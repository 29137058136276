import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useKeysStore } from "../../store/keysStore";

export const EditKeyDialog = ({ showDlg, handleClose, selectedKey }) => {
  const { updateKey, updateLoading } = useKeysStore();

  const [keyUser, setKeyUser] = useState("");

  useEffect(() => {
    if (showDlg && selectedKey) {
      setKeyUser(selectedKey.user !== "Unused" ? selectedKey.user : "");
    }
  }, [showDlg, selectedKey]);

  const handleSave = () => {
    updateKey(selectedKey.id, keyUser, handleClose);
  };

  return (
    <Dialog
      open={showDlg}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Remove/Update Key User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="User"
          type="text"
          fullWidth
          value={keyUser}
          onChange={(e) => setKeyUser(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={updateLoading}
          variant="outlined"
          onClick={handleClose}
          color="error"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={updateLoading}
          variant="contained"
          onClick={() => handleSave(keyUser)}
        >
          {updateLoading ? "Updaing..." : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
