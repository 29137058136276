import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Form, Button } from "react-bootstrap";
import { useAuthStore } from "../../store/authStore";
import { useNavigate, useParams } from "react-router-dom";

export const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { loading, resetPassword } = useAuthStore();

  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("katana-admin-forget-password-token", token);
    }
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.confirmPassword && values.newPassword) {
      if (values.newPassword === values.confirmPassword) {
        resetPassword({ password: values.confirmPassword, navigate, token });
      } else {
        toast.error("Password Not Match");
      }
    } else {
      toast.error("Please Enter Values");
    }
  };
  return (
    <div className="d-flex justify-content-center">
      <Form className="mt-3" style={{ width: "20rem" }}>
        <Form.Group className="text-start mb-3">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="New Password"
            value={values.password}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
            onChange={(e) =>
              setValues({ ...values, newPassword: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="text-start">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={values.confirmPassword}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
            onChange={(e) =>
              setValues({ ...values, confirmPassword: e.target.value })
            }
          />
        </Form.Group>
        <Form.Group className="mb-1 text-end">
          <Form.Label
            onClick={() => navigate("/login")}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Login?
          </Form.Label>
        </Form.Group>
        <Button
          className="w-50"
          variant="primary"
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Form>
    </div>
  );
};
