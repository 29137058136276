import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGameStore } from "../store/gameStore";
import Select from "react-select";

const EditGameSchema = Yup.object().shape({
  gameName: Yup.string().required("Game Name is required"),
  url: Yup.string().required("url is required"),
  zipName: Yup.string().required("ZIP Name is required"),
  fileSize: Yup.string().required("File Size is required"),
  totalFiles: Yup.string().required("Total Size is required"),
  version: Yup.string().required("version is required"),
  googleDriveExePath: Yup.string().required("googleDriveExePath is required"),
});
const EditModal = ({ show, handleClose, data }) => {
  const { editGame, refreshFiles } = useGameStore();
  const [loadingRefresh, setLoadingRefresh] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      gameName: data?.name || "",
      driveId: data?.googleDriveId || "",
      version: data?.version || "",
      fileSize: data?.fileSize || "",
      totalFiles: data?.totalFiles || "",
      googleDriveExePath: data?.googleDriveExePath || "",
      selectedOption: [{ value: data?.type, label: data?.type }],
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const payload = {
          gameId: data?.gameId || null,
          _id: data?._id || null,
          googleDriveExePath: values.googleDriveExePath,
          version: values.version,
          driveId: values.driveId,
          fileSize: values.fileSize,
          totalFiles: values.totalFiles,
          gameName: values.gameName,
          type: values?.selectedOption?.value,
        };
        await editGame(payload);
        handleClose();
      } catch (error) {
        console.error("Error editing game:", error);
      }
    },
  });

  const handleRefresh = async (driveID) => {
    try {
      // Replace 'yourApiEndpoint' with the actual endpoint of your custom API
      // const response = await fetch('http://192.168.1.6:5000/api/v1/game/getGameStructure', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //     folderId: data.driveId,
      //   }),
      // });

      // const result = await response.json();

      setLoadingRefresh(true);

      const payload = {
        folderId: driveID,
      };
      const result = await refreshFiles(payload);

      // Extract data from the API response
      const { files, length } = result.data.data;

      // Update formik values
      formik.setValues({
        ...formik.values,
        fileSize: files,
        totalFiles: length,
      });

      // You can also set the touched flag to trigger validation
      formik.setTouched({
        ...formik.touched,
        fileSize: true,
        totalFiles: true,
      });
      setLoadingRefresh(false);
    } catch (error) {
      console.error("Error fetching data from the API:", error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Settings</Modal.Title>
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="validationCustom01">
              <Form.Label>Game name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Game name"
                name="gameName"
                value={formik.values.gameName}
                onChange={formik.handleChange}
                isInvalid={formik.errors.gameName}
              />
              {formik.touched.gameName && formik.errors.gameName ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.gameName}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label>Drive ID</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Google Drive ID"
                name="driveId"
                value={formik.values.driveId}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.driveId}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.driveId}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom04">
              <Form.Label>Drive Exe Path</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Google Drive Exe Path"
                name="googleDriveExePath"
                value={formik.values.googleDriveExePath}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.googleDriveExePath}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.googleDriveExePath}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <Form.Label>Version No.</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="version"
                name="version"
                value={formik.values.version}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.version}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.version}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label>Game Type</Form.Label>
              <Select
                required
                name="selectedOption"
                value={formik.values.selectedOption}
                onChange={(selectedOption) =>
                  formik.setFieldValue("selectedOption", selectedOption)
                }
                options={[
                  { value: "Development", label: "Development" },
                  { value: "Production", label: "Production" },
                  // Add more options as needed
                ]}
                isInvalid={formik.errors.selectedOption}
              />
              {formik.touched.selectedOption && formik.errors.selectedOption ? (
                <div className="invalid-feedback d-block">
                  {formik.errors.selectedOption}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <Form.Label>File Size</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="File Size"
                name="fileSize"
                value={formik.values.fileSize}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.fileSize}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.fileSize}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <Form.Label>Total Files</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Total Files"
                name="totalFiles"
                value={formik.values.totalFiles}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.totalFiles}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.totalFiles}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="mb-3"></div>
            <Form.Group controlId="refreshButton">
              <Button
                variant="primary"
                onClick={() => handleRefresh(formik.values.driveId)}
                disabled={loadingRefresh || loadingSubmit}
              >
                {loadingRefresh ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Refreshing...
                  </>
                ) : (
                  "Refresh"
                )}
              </Button>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              type="submit"
              variant="primary"
              disabled={loadingRefresh || loadingSubmit}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditModal;
