import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Stack from "@mui/material/Stack";
import Logo from "./../utils/svgs/SideBarLogo.svg";
import { Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EditModal from "./EditModal";

export default function MobileAdminDrawer() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const [gameData, setGameData] = useState();
  const [show, setShow] = useState(false);

  const handleShow = (data) => {
    setGameData(data);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const handleNav = (link) => {
    navigate(link);
    setState({ left: false });
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ left: open });
  };

  const list = () => (
    <Box
      sx={250}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Stack justifyContent="center" alignItems="center">
          <img src={Logo} />
        </Stack>
        <br />
        <Divider />
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleNav("/")}>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  color: "#5B136B",
                  fontWeight: "bold",
                },
              }}
              primary={"Home"}
            />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
        <ListItem disablePadding>
          {role === "admin" || role === "manager" ? (
            <ListItemButton onClick={() => handleShow(null)}>
              <ListItemText
                primaryTypographyProps={{
                  sx: { color: "#5B136B", fontWeight: "bold" },
                }}
                primary={"Add New Game"}
              />
            </ListItemButton>
          ) : (
            ""
          )}
        </ListItem>
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
        {role === "admin" || role === "subAdmin" ? (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNav("/whitelist-users")}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: "#5B136B", fontWeight: "bold" },
                  }}
                  primary={"Babydoge Whitelist"}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />

            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNav("/whitelist-okx")}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: "#5B136B", fontWeight: "bold" },
                  }}
                  primary={"OKX Whitelist"}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
          </>
        ) : (
          ""
        )}
        {role === "admin" && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleNav("/generate-keys")}
                sx={{ fontWeight: "bold" }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: "#5B136B", fontWeight: "bold" },
                  }}
                  primary={"Generate Keys"}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
          </>
        )}
        <ListItem disablePadding>
          {role === "admin" || role === "manager" ? (
            <ListItemButton
              onClick={() => handleNav("/create-user")}
              sx={{ fontWeight: "bold" }}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: { color: "#5B136B", fontWeight: "bold" },
                }}
                primary={"Add Test Users"}
              />
            </ListItemButton>
          ) : (
            ""
          )}
        </ListItem>
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />{" "}
      </List>
    </Box>
  );

  return (
    <Stack display={{ xs: "inherit", md: "none" }}>
      {/* {["left", "right", "top", "bottom"].map((anchor) => ( */}
      <React.Fragment>
        <Button onClick={toggleDrawer(true)}>
          <MenuIcon style={{ color: "#5B136B" }} fontSize="large" />
        </Button>
        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer(false)}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      {/* ))} */}
      <EditModal show={show} handleClose={handleClose} data={gameData} />
    </Stack>
  );
}
