import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useState } from "react";
import "../utils/css/nftForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiKey = "7f1c8ed854348d378814"; // <---------- your Pinata Project ID
const apiSecret =
  "bc70db1ecb9c3948540dbc3887c4aad3c426b98c11e803ecf41014d07f849f40"; // <---------- your Pinata Secret

const MintForm = ({ nft, marketplace }) => {
  const nav = useNavigate();
  const [imageHash, setImageHash] = useState("");
  const [img, setImg] = useState(null);
  const [amount, setAmount] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [formFields, setFormFields] = useState([
    { name: "", value: "", type: "" },
  ]);
  const [imgFile, setFile] = useState(null);
  const token = localStorage.getItem("katana-admin-token");

  const handleChange = (index, key, value) => {
    setFormFields((prevFields) => {
      const newAttributes = [...prevFields];
      newAttributes[index] = {
        ...newAttributes[index],
        [key]: value,
      };
      return newAttributes;
    });
  };

  const handleSelect = (extraParam) => (eventKey) => {
    handleChange(extraParam, "type", eventKey);
  };

  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const addFields = () => {
    let object = {
      name: "",
      value: "",
      type: "",
    };

    setFormFields([...formFields, object]);
  };

  //Uploading to IPFS
  const uploadToIPFS = async () => {
    // const file = event.target.files[0];
    if (img) {
      try {
        const formData = new FormData();
        formData.append("file", img);

        const resFile = await axios({
          method: "post",
          url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
          data: formData,
          headers: {
            pinata_api_key: `${apiKey}`,
            pinata_secret_api_key: `${apiSecret}`,
            "Content-Type": "multipart/form-data",
          },
        });

        const ImgHash = `https://ipfs.io/ipfs/${resFile.data.IpfsHash}`;
        setImageHash(ImgHash);
      } catch (error) {
      }
    }
  };

  const imgUp = (e) => {
    setImg(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmitHandler = async () => {
    await uploadToIPFS();
    let data = JSON.stringify({
      name: name,
      description: description,
      image: imageHash,
      amount: Number(amount),
      attributes: formFields,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://launcherapi.katanainu.com/api/v1/nft/create",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    const response = await axios.request(config);
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => nav("/")}
        style={{
          top: 10,
          right: 10,
          fontSize: 16,
          fontWeight: 600,
          position: "absolute",
          borderColor: "#712083",
          backgroundColor: "#712083",
          textTransform: "uppercase",
        }}
      >
        Back
      </Button>
      <h1>Add NFT Data</h1>
      {/* <hr /> */}
      <div className="container-fluid">
        <div className="row">
          <main role="main" className="col-lg-12 d-flex text-center">
            <div className="mx-auto">
              <Form.Group className="left mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Name"
                />
              </Form.Group>
              <Form.Group className="left mb-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter Amount"
                />
              </Form.Group>
              <Form.Group className="left mb-3" controlId="formBasicEmail">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter Description"
                  as="textarea"
                />
              </Form.Group>
              <Form.Group className="left mb-3" controlId="formBasicEmail">
                <Form.Label>File</Form.Label>
                <div class="upload-btn-wrapper">
                  <button className="btn-file">
                    {imgFile ? (
                      <img src={imgFile} alt="" />
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="image"
                      >
                        <path
                          fill="#464646"
                          d="M19 2H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Zm-9 5a2 2 0 1 1-2 2 2 2 0 0 1 2-2ZM7.49 20H5a1 1 0 0 1-.94-.71l3.09-3.71a1.08 1.08 0 0 1 .79-.36 1 1 0 0 1 .78.4l1 1.33ZM20 19a1 1 0 0 1-1 1h-9l5.47-7.52a1 1 0 0 1 .73-.41 1 1 0 0 1 .79.29L20 15.41Z"
                        ></path>
                      </svg>
                    )}
                  </button>
                  <input type="file" name="myfile" onChange={imgUp} />
                </div>
              </Form.Group>
              <Form.Label className="heading">Properties</Form.Label>
              {formFields.map((form, index) => {
                return (
                  <div key={index}>
                    <Form.Group className="attributes left mb-3">
                      <Form.Label className="attr-label">Type</Form.Label>
                      <Form.Control
                        onChange={(event) =>
                          handleChange(index, "name", event.target.value)
                        }
                        placeholder="Enter Name"
                        className="w-75"
                      />
                      &nbsp;&nbsp;
                      <Form.Label className="attr-label">Value</Form.Label>
                      <InputGroup>
                        <Form.Control
                          onChange={(event) =>
                            handleChange(index, "value", event.target.value)
                          }
                          aria-describedby="basic-addon2"
                        />
                        <DropdownButton
                          variant="outline-secondary"
                          title="Display type"
                          id="input-group-dropdown-2"
                          value={formFields[index].type}
                          align="end"
                          onSelect={handleSelect(index)}
                        >
                          <Dropdown.Item eventKey="Date">Date</Dropdown.Item>
                          <Dropdown.Item eventKey="String">
                            String
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Number">
                            Number
                          </Dropdown.Item>
                        </DropdownButton>
                        <Button
                          variant="secondary"
                          size="sm"
                          id="button-addon2"
                          onClick={() => removeFields(index)}
                        >
                          &nbsp;X&nbsp;
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  </div>
                );
              })}
              <div className="left mb-3">
                <Button
                  className="btn-add"
                  size="sm"
                  variant="primary"
                  onClick={addFields}
                >
                  Add More..
                </Button>
              </div>
            </div>
          </main>
          <div className="btn-sub mt-3 mb-5">
            <Button
              size="lg"
              className="btn-add"
              onClick={onSubmitHandler}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MintForm;
