import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const KeysTabs = ({ setKeyType, setPageNumber, value, setValue }) => {
  const handleChange = (_, newValue) => {
    if (newValue === 0) {
      setKeyType("primary");
    } else {
      setKeyType("special");
    }
    setPageNumber(1);
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          <Tab
            {...a11yProps(0)}
            label="Primary Keys"
            sx={{
              textTransform: "capitalize",
              paddingBottom: 0,
              fontWeight: value === 0 ? "bold" : "light",
            }}
          />
          <Tab
            {...a11yProps(1)}
            label="Special Keys"
            sx={{
              textTransform: "capitalize",
              paddingBottom: 0,
              fontWeight: value === 1 ? "bold" : "light",
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};
