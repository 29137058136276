import { Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTestUserStore } from "../store/testUserStore";
import { convertDate, convertTime } from "../utils/Conversions";
import NewUserModal from "./NewUserModal";
import AssignGameIdModal from "./AssignGameIdModal";

const tableCustomStyles = {
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "bold",
      backgroundColor: "#5B136B",
      color: "white",
    },
  },
};
const AddTestUser = () => {
  const { getTestUser, deleteTestUser, users, loading } = useTestUserStore();

  const [show, setShow] = useState(false);
  const [assignShow, setAssignShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleAssignClose = () => setAssignShow(false);

  const handleDeleteUser = async (id) => {
    await deleteTestUser(id?._id);
  };

  useEffect(() => {
    getTestUser();
  }, []);
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">ID</span>,
      selector: (row) => row?._id && row?._id,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Email</span>,
      selector: (row) => row?.email && row?.email,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Nick Name</span>,
      selector: (row) => row?.nickName && row?.nickName,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Game ID</span>,
      selector: (row) => (row?.gameId ? row?.gameId : "N/A"),
      sortable: true,
      center: true,
    },

    {
      name: <span className="font-weight-bold fs-13">Added On</span>,
      selector: (row) =>
        row?.createdAt &&
        `${convertDate(row?.createdAt)} ${convertTime(row?.createdAt)}`,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-weight-bold fs-13">Action</span>,
      cell: (id) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            {/* <li className="list-inline-item edit">
              <button
                className="text-primary d-inline-block btn btn-light"
                // onClick={() => handleShow(id)}
              >
                <i className="ri-pencil-fill fs-16"></i>
              </button>
            </li> */}
            <li className="list-inline-item">
              <button
                className="text-danger d-inline-block btn btn-light"
                onClick={() => handleDeleteUser(id)}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </button>
            </li>
          </ul>
        );
      },
    },
  ];

  return (
    <Stack>
      <Typography variant="h4">Test Users</Typography>
      <Stack direction={"row"} justifyContent={"end"} spacing={2}>
        <Button
          variant="primary"
          onClick={() => setAssignShow(true)}
          style={{
            fontSize: 16,
            fontWeight: 600,
            // position: "absolute",
            alignSelf: "end",
            marginBottom: "1em",
            width: "fit-content",
            borderColor: "#712083",
            backgroundColor: "#712083",
            color: "white",
            textTransform: "uppercase",
          }}
        >
          Assign Game
        </Button>
        <Button
          variant="primary"
          onClick={() => setShow(true)}
          style={{
            fontSize: 16,
            fontWeight: 600,
            // position: "absolute",
            alignSelf: "end",
            marginBottom: "1em",
            width: "fit-content",
            borderColor: "#712083",
            backgroundColor: "#712083",
            color: "white",
            textTransform: "uppercase",
          }}
        >
          Add New User
        </Button>
      </Stack>
      {users && (
        <DataTable
          columns={columns}
          data={users}
          pagination
          customStyles={tableCustomStyles}
        />
      )}
      <NewUserModal show={show} handleClose={handleClose} />
      <AssignGameIdModal show={assignShow} handleClose={handleAssignClose} />
    </Stack>
  );
};

export default AddTestUser;
