import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { ReactComponent as Logo } from "./../utils/svgs/SideBarLogo.svg";
import { Menu } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EditModal from "./EditModal";

const AdminDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("role");
  const [gameData, setGameData] = useState();
  const [show, setShow] = useState(false);

  const handleShow = (data) => {
    setGameData(data);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const handleNav = (link) => {
    navigate(link);
    setState({ left: false });
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ left: open });
  };

  const list = () => (
    <Box
      sx={250}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Stack justifyContent="center" alignItems="center">
          <Logo />
        </Stack>
        <br />
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleNav("/")}
            sx={{
              backgroundColor:
                location.pathname === "/" ? "#efefef" : "transparent",
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  color: "#5B136B",
                  fontWeight: "bold",
                },
              }}
              primary={"Home"}
            />
          </ListItemButton>
        </ListItem>
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
        <ListItem disablePadding>
          {role === "admin" || role === "manager" ? (
            <ListItemButton onClick={() => handleShow(null)}>
              <ListItemText
                primaryTypographyProps={{
                  sx: { color: "#5B136B", fontWeight: "bold" },
                }}
                primary={"Add New Game"}
              />
            </ListItemButton>
          ) : (
            ""
          )}
        </ListItem>
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
        {role === "admin" || role === "subAdmin" ? (
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNav("/whitelist-users")}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: "#5B136B", fontWeight: "bold" },
                  }}
                  primary={"Babydoge Whitelist"}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />

            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNav("/whitelist-okx")}>
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: "#5B136B", fontWeight: "bold" },
                  }}
                  primary={"OKX Whitelist"}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
          </>
        ) : (
          ""
        )}
        {role === "admin" && (
          <>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => handleNav("/generate-keys")}
                sx={{
                  backgroundColor:
                    location.pathname === "/generate-keys"
                      ? "#efefef"
                      : "transparent",
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: { color: "#5B136B", fontWeight: "bold" },
                  }}
                  primary={"Generate Keys"}
                />
              </ListItemButton>
            </ListItem>
            <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />
          </>
        )}
        <ListItem disablePadding>
          {role === "admin" || role === "manager" ? (
            <ListItemButton
              onClick={() => handleNav("/create-user")}
              sx={{
                backgroundColor:
                  location.pathname === "/create-user"
                    ? "#efefef"
                    : "transparent",
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: { color: "#5B136B", fontWeight: "bold" },
                }}
                primary={"Add Test Users"}
              />
            </ListItemButton>
          ) : (
            ""
          )}
        </ListItem>
        <Divider sx={{ borderColor: "rgb(0 0 0 / 67%)" }} />{" "}
        {/* <ListItem disablePadding>
          {role === "admin" && (
            <ListItemButton
              onClick={() => handleNav("/upload-build")}
              sx={{
                backgroundColor:
                  location.pathname === "/upload-build"
                    ? "#efefef"
                    : "transparent",
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: { color: "#5B136B", fontWeight: "bold" },
                }}
                primary={"Upload New Build"}
              />
            </ListItemButton>
          )}
        </ListItem> */}
      </List>
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>*/}
    </Box>
  );

  return (
    <Stack width={"100%"}>
      {/* {["left", "right", "top", "bottom"].map((anchor) => ( */}
      <React.Fragment>
        {/* <Button onClick={toggleDrawer(true)}>
          <MenuIcon style={{ color: "#5B136B" }} fontSize="large" />
        </Button> */}

        <Drawer
          anchor={"left"}
          open={state["left"]}
          onClose={toggleDrawer(false)}
          variant="permanent"
          sx={{
            flexShrink: 0,
            height: "100%",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              position: "relative",
              height: "100%",
              overflow: "hidden",
              zIndex: "0 !important",
            },
          }}
        >
          {list("left")}
        </Drawer>
      </React.Fragment>
      {/* ))} */}
      <EditModal show={show} handleClose={handleClose} data={gameData} />
    </Stack>
  );
};

export default AdminDrawer;
