import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Button, Form } from "react-bootstrap";
import { useKeysStore } from "../store/keysStore";
import { useNavigate } from "react-router-dom";

export const KeysForm = ({ resetStates }) => {
  const navigate = useNavigate();
  const { getKeys, loading } = useKeysStore();

  const [numberOfKeys, setNumberOfKeys] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (numberOfKeys > 0) {
      getKeys(numberOfKeys);
      resetStates();
    } else {
      toast.error("Please Enter Number");
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-center mb-2">
        <Form style={{ width: "20rem" }}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Enter Number Of Keys</Form.Label>
            <Form.Control
              type="number"
              value={numberOfKeys}
              placeholder="Enter Value"
              onClick={(e) => e.target.select()}
              onChange={(e) => setNumberOfKeys(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleSubmit(e);
              }}
            />
          </Form.Group>
          <Button variant="primary" disabled={loading} onClick={handleSubmit}>
            {loading ? "Fetching Keys..." : "Generate Keys"}
          </Button>
          <Button style={{ marginLeft: "5px" }} onClick={() => navigate("/")}>
            Game Settings
          </Button>
        </Form>
      </div>
    </div>
  );
};
