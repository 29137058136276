import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/authStore";

export const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { loading, forgetPassword } = useAuthStore();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      forgetPassword({ email, navigate });
    } else {
      toast.error("Please Enter Values");
    }
  };
  return (
    <div className="d-flex justify-content-center">
      <Form className="mt-3" style={{ width: "20rem" }}>
        <Form.Group className="text-start">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleSubmit(e);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-1 text-end">
          <Form.Label
            onClick={() => navigate("/login")}
            style={{ cursor: "pointer", color: "grey" }}
          >
            Login?
          </Form.Label>
        </Form.Group>
        <Button
          className="w-50"
          variant="primary"
          disabled={loading}
          onClick={handleSubmit}
        >
          {loading ? "Submitting..." : "Submit"}
        </Button>
      </Form>
    </div>
  );
};
