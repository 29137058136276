import React, { useEffect, useState } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/authStore";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LogoutIcon from "@mui/icons-material/Logout";
import EditModal from "../components/EditModal";
import MobileAdminDrawer from "../components/mobileSideBar";

const Header = () => {
  const navigate = useNavigate();
  const { adminLogout } = useAuthStore();
  const [gameData, setGameData] = useState();
  const [show, setShow] = useState(false);

  const brandStyle = {
    color: "#5B136B",
    fontWeight: "bold",
  };
  const role = localStorage.getItem("role");

  const handleShow = (data) => {
    setGameData(data);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  return (
    <Stack justifyContent="space-between" direction="row" position={"relative"}>
      {/* <Stack direction="row"> */}
      <MobileAdminDrawer />
      <h1>KATANA ADMIN</h1>
      {/* </Stack> */}
      <Tooltip title="Logout" placement="bottom">
        <IconButton
          variant="primary"
          sx={{ right: "0px", top: "0px" }}
          onClick={() => adminLogout({ navigate })}>
          <LogoutIcon />
        </IconButton>
      </Tooltip>
    </Stack>

    // <>
    //   <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
    //     <Container>
    //       <Navbar.Brand href="/" style={brandStyle}>
    //         KATANA ADMIN
    //       </Navbar.Brand>
    //       <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    //       <Navbar.Collapse id="responsive-navbar-nav">
    //         <Nav className=" justify-content-center">
    //           <Nav.Link onClick={() => handleShow(null)}>Add New Game</Nav.Link>
    //           <Nav.Link onClick={() => navigate("/whitelist-users")}>
    //             Babydoge Whitelist Users
    //           </Nav.Link>
    //         </Nav>
    //         <Nav>
    //           {role === "admin" && (
    //             <Nav.Link onClick={() => navigate("/generate-keys")}>
    //               Generate Keys
    //             </Nav.Link>
    //           )}
    //           <Tooltip title="Logout" placement="bottom">
    //             <IconButton
    //               variant="primary"
    //               sx={{
    //                 position: "absolute",
    //                 right: "0px",
    //                 top: "0px",
    //                 color: "#5B136B",
    //               }}
    //               onClick={() => adminLogout({ navigate })}
    //             >
    //               <LogoutIcon />
    //             </IconButton>
    //           </Tooltip>
    //         </Nav>
    //       </Navbar.Collapse>
    //     </Container>
    //   </Navbar>
    //   <EditModal show={show} handleClose={handleClose} data={gameData} />
    // </>
  );
};

export default Header;
