import { create } from "zustand";
import { toast } from "react-hot-toast";
import axios from "../axios/axiosConfig";

export const useTestUserStore = create((set) => ({
  users: null,
  loading: null,
  getTestUser: async () => {
    try {
      set({ loading: true });
      const response = await axios.get("/users/testers");
      toast.success("Success");
      set({ loading: false, users: response.data.data });
    } catch (error) {
      set({ loading: false });
    }
  },
  createTestUser: async (payload) => {
    try {
      set({ loading: true });
      const response = await axios.post("/signUp-tester", payload);
      const updatedUsers = await axios.get("/users/testers");
      set({ loading: false, users: updatedUsers?.data?.data });
      return response;
    } catch (error) {
      set({ loading: false });
      return error;
    }
  },
  setNormalToTestUser: async (payload) => {
    try {
      set({ loading: true });
      const response = await axios.post("/users/update", payload);
      const updatedUsers = await axios.get("/users/testers");
      set({ loading: false, users: updatedUsers?.data?.data });
      return response;
    } catch (error) {
      set({ loading: false });
      return error;
    }
  },
  deleteTestUser: async (id) => {
    try {
      set({ loading: true });
      const response = await axios.delete(`users/delete/${id}`);
      const updatedUsers = await axios.get("/users/testers");
      set({ loading: false, users: updatedUsers?.data?.data });
      toast.success("Success Deleting User");
      return response;
    } catch (error) {
      set({ loading: false });
      return error;
    }
  },
}));
