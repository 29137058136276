export const convertDate = (timestamp) => {
  // Convert to date object
  const dateObj = new Date(timestamp);

  // Convert to "29 May, 2023" format
  const formattedDate = dateObj.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};

export const convertTime = (timestamp) => {
  // Convert to date object
  const dateObj = new Date(timestamp);

  // Convert to "29 May, 2023" format
  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  return formattedTime;
};
