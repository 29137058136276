import React from "react";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export const FilterDropdown = ({ type, setType }) => {
  return (
    <Box sx={{ minWidth: 80, paddingTop: "7px" }}>
      <FormControl fullWidth>
        <Select
          value={type}
          label="Key Type"
          variant="standard"
          labelId="key-type-select-label"
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value={"used"}>Used</MenuItem>
          <MenuItem value={"unused"}>Unused</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
