import { Stack } from "@mui/material";
import React from "react";
import "./App.css";
import MainRoutes from "./utils/Routes";

function App() {
  return (
    <div className="App">
      {/* <Stack pt={5}> */}
      <MainRoutes />
      {/* </Stack> */}
    </div>
  );
}

export default App;
