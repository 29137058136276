import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React from "react";
import Header from "./Header";
import AdminDrawer from "../components/SideBar";
import { Padding } from "@mui/icons-material";

const Layout = ({ children }) => {
  return (
    // <Container maxWidth={false}>
    <Grid container height={"95vh"} spacing={2}>
      <Grid item xs={2} display={{ xs: "none", md: "inherit" }}>
        <AdminDrawer />
      </Grid>
      {/* <Grid xs={2} display={{xs:'inherit', md:'none'}}>

        </Grid> */}
      <Grid item xs={12} md={10} sx={{ paddingRight: "10px" }}>
        {/* <Stack px={"4em"}> */}

        <Header />
        {children}
      </Grid>
    </Grid>
    // </Container>
  );
};

export default Layout;

//*******first */
{
  /* <Stack direction="row">
  <AdminDrawer />
  <Stack flexGrow={1}>
    <Header />
    {children}
  </Stack>
</Stack>; */
}
