import { create } from "zustand";
import axios from "../axios/axiosConfig";

export const useGameStore = create((set) => ({
  loading: false,
  games: null,
  setGame: async (payload) => {
    try {
      set({ loading: true });
      // const response = await axios.post("game/createGame", { payload });
      set({ loading: false });
    } catch (error) {
      set({ loading: false });
      //   toast.error(error.response.data.data.message);
    }
  },
  getGame: async (payload) => {
    try {
      set({ loading: true });
      const response = await axios.get("game/getAllGames");
      set({ loading: false, games: response.data.data.data });
    } catch (error) {
      set({ loading: false });
    }
  },
  editGame: async (payload) => {
    try {
      set({ loading: true });
      const response = await axios.post("game/editGame", payload);
      const updatedGame = await axios.get("game/getAllGames");
      set({ loading: false, games: updatedGame.data.data.data });
    } catch (error) {
      set({ loading: false });
    }
  },
  refreshFiles: async (payload)=>{
    try {
      const response = await axios.post("game/getGameStructure", payload);
      set({ loading: false});
      return response;
    } catch (error) {
      console.log("🚀 ~ file: addGame.js:13 ~ setGame: ~ error:", error);
    }
  },
  deleteGame: async (payload) => {
    try {
      set({ loading: true });
      // const response = await axios.delete(`game/deleteGame?gameId=${payload}`);
      const updatedGame = await axios.get("game/getAllGames");
      set({ loading: false, games: updatedGame.data.data.data });
    } catch (error) {
      console.log("🚀 ~ file: addGame.js:13 ~ setGame: ~ error:", error);
      set({ loading: false });
    }
  },
  clearGameState: () => {
    set({ loading: false });
  },
}));
